.container {
    @apply pt-2 grid grid-flow-col md:overflow-hidden gap-4 relative;

    grid-auto-columns: calc(25% - 12px);
}


@media (max-width: 768px) {
    .container {
        /*snap-x snap-mandatory */
        @apply px-4 grid-cols-none gap-2.5 overflow-y-scroll;
        grid-auto-columns: calc(50% - 8px);
    }
}
