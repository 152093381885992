.move {
    @apply flex bg-white w-12 h-12 justify-center items-center rounded-full cursor-pointer;
}

.container {
    @apply flex gap-4 p-4 w-full items-center justify-between;
}

.title {
    @apply w-full justify-between md:justify-center flex items-center pb-2 hover:text-blue-600;
}

.titleLink {
    @apply truncate px-4 md:pb-4 text-lg md:text-3xl font-semibold md:font-semibold;
}

.categoryLink {
    @apply text-blue-700 lg:text-lg hover:text-blue-500 whitespace-nowrap px-2;
}

.categorySlide {
    @apply top-0 absolute w-full h-full flex items-center justify-center cursor-pointer rounded-md;
}

.categorySlideTitleWrap {
    @apply flex flex-col;
    max-width: 60%;
}
